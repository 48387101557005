import { Col, Form, Row } from "react-bootstrap";


const Cliente = ({ handleChange, formData, listaFormaDePago }) => {
  return (
    <Row className="bg-white mb-3 p-4 pb-2 border rounded m-5">
      <Col s={12} md={6}>
        <Form.Group className="mb-3">
          <Form.Label>Cliente: </Form.Label>
          <Form.Control
            type="text"
            name="clienteNombre"
            defaultValue={formData.clienteNombre}
            readOnly
            disabled
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Dirección</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            name="billTo"
            defaultValue={formData.billTo}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>
      </Col>
      <Col s={12} md={6}>
        <Form.Group className="mb-3">
          <Form.Label>Forma de pago</Form.Label>
          <Form.Select
            defaultValue={formData.formaDePago}
            name="formaDePago"
            onChange={handleChange}
          // className={emptyFormaDePago ? `is-invalid` : ""}
          >
            <option value={0}>Seleccione</option>
            {listaFormaDePago.map((item, i) => (
              <option key={i} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Dirección de envío</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            name="shipTo"
            defaultValue={formData.shipTo}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>
      </Col>
      <Col s={12} className="row">
        <Form.Group className="mb-3 col-6">
          <Form.Label>Ship Via: </Form.Label>
          <Form.Control
            type="text"
            name="shipVia"
            defaultValue={formData.shipVia}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-6">
          <Form.Label>Terms: </Form.Label>
          <Form.Control
            type="text"
            name="Terms"
            defaultValue={formData.Terms}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-6">
          <Form.Label>Sales Person: </Form.Label>
          <Form.Control
            type="text"
            name="salesPerson"
            defaultValue={formData.salesPerson}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-6">
          <Form.Label>Peso: </Form.Label>
          <Form.Control
            type="text"
            name="peso"
            defaultValue={formData.peso}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-6">
          <Form.Label>Código de seguimiento: </Form.Label>
          <Form.Control
            type="text"
            name="seguimiento"
            value={formData.seguimiento}
            onChange={handleChange}
          />
        </Form.Group>
      </Col>
      <Col s={12} className="row">
        <Form.Group className="mb-3 col-6">
          <Form.Label>FOB: </Form.Label>
          <Form.Control
            type="text"
            name="FOB"
            defaultValue={formData.FOB}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-6">
          <Form.Label>Fecha de orden: </Form.Label>
          <Form.Control
            type="date"
            name="fechaOrden"
            defaultValue={formData.fechaOrden}
            readOnly
            disabled
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-6">
          <Form.Label>Transporte</Form.Label>
          <Form.Control
            type="text"
            name="UPS"
            defaultValue={formData.UPS}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-6">
          <Form.Label>Orden: </Form.Label>
          <Form.Control
            type="text"
            name="orden"
            defaultValue={formData.orden}
            readOnly
            disabled
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-6">
          <Form.Label></Form.Label>
          <Form.Control
            type="text"
            name="empty"
            readOnly
            plaintext
          ></Form.Control>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default Cliente;
