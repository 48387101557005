import React, { useContext, useEffect, useState } from "react"; // Asegúrate de incluir useEffect y useState
import { useParams } from "react-router-dom"; // Para usar useParams
import axios from "axios"; // Para realizar solicitudes HTTP
import { Col, Row } from "react-bootstrap"; // Asegúrate de tener instalado react-bootstrap
import SingleCustomerTable from "./SingleCustomerTable"; // Verifica que el path sea correcto
import DataContext from "../../../_helper/DataContext"; // Verifica que el path sea correcto
import { Breadcrumbs } from "../../../AbstractElements"; // Si se usa Breadcrumbs

const CtaCteDetail = () => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const [formData, setFormData] = useState({ resultados: [], cliente: "", ctacteTotal: 0 });
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(15); // Cantidad por página predeterminada
  const [page, setPage] = useState(1); // Página actual
  const [loading, setLoading] = useState(false);

  async function getDetailCtaCte(pagina = 1, cantidad = perPage) {
    setLoading(true);
    try {
      const response = await axios({
        url: `${initialENDPOINT}/cuentacorriente/${id}?pagina=${pagina}&cantidad=${cantidad}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setFormData({
        resultados: response.data.original.results,
        cliente: response.data.original.cliente,
        ctacteTotal: response.data.original.ctacteTotal,
      });
      setTotalRows(response.data.original.cantidad_total);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setFormData({ resultados: [], cliente: "", ctacteTotal: 0 });
      setTotalRows(0);
      setLoading(false);
    }
  }

  useEffect(() => {
    getDetailCtaCte(page, perPage); // Carga inicial
  }, [page, perPage]); // Recarga cuando cambia la página o el número de filas por página

  return (
    <Row>
      <Col>
        <SingleCustomerTable
          data={formData}
          loading={loading}
          totalRows={totalRows}
          perPage={perPage}
          setPerPage={setPerPage}
          page={page}
          setPage={setPage}
          onPageChange={(newPage) => setPage(newPage)} // Cambio de página
          onPerPageChange={(newPerPage) => setPerPage(newPerPage)} // Cambio de filas por página
        />
      </Col>
    </Row>
  );
};

export default CtaCteDetail;
