import { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { downloadCSV, Export } from "../../../_helper/hooks/useExportCsv";
import Loader from "../../Common/Loader";
import Vacio from "../../Common/Vacio";
import TableTitle from "../../Common/TableTitle";
import { Breadcrumbs } from "../../../AbstractElements";

const paginacionOpciones = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
};
const contextOpciones = {
  singular: "",
  plural: "",
  message: "",
};
const customStyles = {
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "rgb(230, 244, 244)",
      borderBottomColor: "#FFFFFF",
      outline: "1px solid #FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

// const SingleCustomerTable = ({
//   data,
//   errMsg,
//   loading,
//   totalRows,
//   perPage,
//   setPerPage,
//   setPage,
//   setENDPOINT,
//   filterENDPOINT,
//   showCancellations,
//   handleShowCancellations,
// }) => {
//   const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
//   const queriesColumns = [
//     {
//       name: "ID",
//       selector: (row) => row.id,
//       center: false,
//       wrap: true,
//       grow: 0,
//     },
//     {
//       name: "Cliente",
//       selector: (row) => row.cliente,
//       center: false,
//       wrap: true,
//     },
//     {
//       name: "Comprobante",
//       selector: (row) => row.comprobante,
//       center: false,
//       hide: "sm",
//       wrap: true,
//     },
//     {
//       name: "Número",
//       selector: (row) => row.numero,
//       center: false,
//       hide: "sm",
//       wrap: true,
//     },
//     {
//       name: "Fecha",
//       selector: (row) => row.fecha,
//       center: false,
//       hide: "sm",
//       wrap: true,
//     },
//     {
//       name: "Total",
//       selector: (row) => row.total,
//       center: false,
//       hide: "sm",
//       wrap: true,
//     },
//     {
//       name: "Detalle",
//       selector: (row) => [
//         <a key={`detail-${row.id}`} href={`${initialENDPOINT}/pdf/invoice/${row.id}`} target="_blank" rel="noreferrer">
//           <button
//             key={2}
//             className="btn text-dark w-auto p-1 mx-1 my-0"
//             type="button"
//             title="Detalle"
//           >
//             <i className="fa fa-file-text f-16 px-1" />
//           </button>
//         </a>,
//       ],
//       center: true,
//       hide: "md",
//       compact: true,
//     },
//   ];

//   const currentURL = window.location.href;

//   const parts = currentURL.split('/');

//   const cliente = parts[parts.length - 1];

//   const handlePageChange = (pag) =>
//   setENDPOINT(
//     `${initialENDPOINT}/cuentacorriente/${cliente}?pagina=${pag}&cantidad=${perPage}`
//   );
// const handlePerRowsChange = async (newPerPage, pag) => {
//   setPerPage(newPerPage);
//   setPage(pag);
//   await setENDPOINT(
//     `${initialENDPOINT}/cuentacorriente/${cliente}?pagina=${pag}&cantidad=${newPerPage}`
//   );
// };

//   const actionsMemo = useMemo(
//     () => (
//       <Export
//         onExport={() => downloadCSV(data, "Consultas")}
//         cancallations={handleShowCancellations}
//       />
//     ),
//     [data]
//   );

//   return (

//     <div
//       className="App mb-5 table-queries border rounded"
//       style={{ overflow: "hidden" }}
//     >
//       <Breadcrumbs
//         mainTitle={`Cuenta Corriente ${data.cliente} ($${data.ctacteTotal})` }
//         parent="Cta. Cte."
//         title="Movimientos"
//       />
//       <DataTable
//         title={<TableTitle value="Lista de movimientos" />}
//         columns={queriesColumns}
//         data={data.resultados}
//         striped
//         customStyles={customStyles}
//         highlightOnHover
//         progressPending={loading}
//         progressComponent={<Loader />}
//         noDataComponent={<Vacio />}
//         pagination
//         paginationServer
//         paginationComponentOptions={paginacionOpciones}
//         paginationDefaultPage
//         paginationTotalRows={totalRows}
//         contextMessage={contextOpciones}
//         onChangePage={handlePageChange}
//         onChangeRowsPerPage={handlePerRowsChange}
//         actions={actionsMemo}
//         persistTableHead
//       />
//     </div>
//   );
// };

const SingleCustomerTable = ({
  data,
  loading,
  totalRows,
  perPage,
  page,
  onPageChange,
  onPerPageChange,
}) => {
  const queriesColumns = [
    {
      name: "ID",
      selector: (row) => row.id,
      wrap: true,
    },
    {
      name: "Cliente",
      selector: (row) => row.cliente,
      wrap: true,
    },
    {
      name: "Comprobante",
      selector: (row) => row.comprobante,
      wrap: true,
    },
    {
      name: "Número",
      selector: (row) => row.numero,
      wrap: true,
    },
    {
      name: "Fecha",
      selector: (row) => row.fecha,
      wrap: true,
    },
    {
      name: "Total",
      selector: (row) => row.total,
      wrap: true,
    },
    {
      name: "Detalle",
      cell: (row) => (
        <a
          href={`${process.env.REACT_APP_ENDPOINT}${row.link}`}
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn text-dark w-auto p-1 mx-1 my-0" type="button" title="Detalle">
            <i className="fa fa-file-text f-16 px-1" />
          </button>
        </a>
      ),
      center: true,
      compact: true,
    },
  ];

  return (
    <div
      className="App mb-5 table-queries border rounded"
      style={{ overflow: "hidden" }}
    >
      <Breadcrumbs
        mainTitle={`Cuenta Corriente ${data.cliente} ($${data.ctacteTotal})`}
        parent="Cta. Cte."
        title="Movimientos"
      />

      <DataTable
        title="Lista de movimientos"
        columns={queriesColumns}
        data={data.resultados}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationRowsPerPageOptions={[10, 15, 25, 50]}
        paginationPerPage={perPage}
        onChangePage={onPageChange}
        onChangeRowsPerPage={onPerPageChange}
      />
    </div>
  );
};
export default SingleCustomerTable;

